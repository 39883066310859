@import '../../../../styles/styles.scss';

.title-bar-container {
    padding-left: 2em;
    width: max-content;
    display: block;
    border-bottom: 2px solid $light-gray-fill;
}

.transactions-list-container {
    display: block;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    height: 60%;
    overflow: auto;
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.transaction-card-container {
    width: 98.5%;
    height: 28%;
    margin: 0.75%;
}

.transaction-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background: $white;
    margin-right: 10px;
}

.arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 8.5px 5px;

    &.decline {
        border-color: transparent transparent $decline-color-light transparent;
    }

    &.increase {
        border-color: transparent transparent $green-increase transparent;
    }
}

.transaction-data-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.state-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
}

.transaction-value {
    font-size: 1em;
    font-family: $font-Comfortaa;
    font-weight: bold;

    &.increase {
        color: $green-increase;
    }

    &.decline {
        color: $decline-color-light;
    }
}
.transaction-date {
    font: $font-OpenSans;
    font-size: 10px;
    color: $space-gray;
}

.view-more-button-container {
    display: flex;
    margin-top: 1%;
    width: 100%;
    justify-content: flex-end;
}
